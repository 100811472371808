import { translate } from '@/core/localization/translate';
export const {
  selectedProductTitle,
  suggestedProductTitle,
  promotedProductTitle,
  recentlyTitle,
  ariaLabelPrevious,
  ariaLabelNext
} = translate({
  "selectedProductTitle": "Our top selection",
  "suggestedProductTitle": "Our top sellers",
  "promotedProductTitle": "Check out our offers!",
  "recentlyTitle": "Recently viewed products",
  "ariaLabelPrevious": "Previous",
  "ariaLabelNext": "Next"
});